document.addEventListener("DOMContentLoaded", function () {
  // Seleziona tutte le immagini dello slider
  document.querySelectorAll(".mcl-slide-simple-slider .mcl-image").forEach(img => {
    img.style.cursor = "pointer"; // Mostra il puntatore
    img.addEventListener("click", function () {
      const imgSrc = this.getAttribute("src");

      // Crea l'overlay del popup
      const popupOverlay = document.createElement("div");
      popupOverlay.classList.add("image-popup-overlay");

      // Crea il contenitore per l'immagine
      const popupContent = document.createElement("div");
      popupContent.classList.add("image-popup-content");

      // Crea l'immagine
      const popupImg = document.createElement("img");
      popupImg.src = imgSrc;

      // Crea il pulsante di chiusura
      const closeButton = document.createElement("button");
      closeButton.innerHTML = "×";
      closeButton.classList.add("image-popup-close");
      closeButton.addEventListener("click", function () {
        popupOverlay.style.opacity = "0";
        popupOverlay.style.visibility = "hidden";
        setTimeout(() => popupOverlay.remove(), 300); // Rimuove l'elemento dopo la transizione
      });

      // Aggiunge gli elementi al popup
      popupContent.appendChild(popupImg);
      popupContent.appendChild(closeButton);
      popupOverlay.appendChild(popupContent);
      document.body.appendChild(popupOverlay);

      // Mostra il popup con effetto
      setTimeout(() => {
        popupOverlay.style.opacity = "1";
        popupOverlay.style.visibility = "visible";
      }, 10);
    });
  });
});
