// Atoms

import './atoms/pill/pill';


// Molecules

import './molecules/accordion/accordion';
import './molecules/accordion-menu-right-drawer/accordion-menu-right-drawer';
import './molecules/cards/card-working-method/card-working-method';
//import './molecules/modal/modal';

// Organisms

import './organisms/header/header';
import './organisms/tab-menu-accordion/tab-menu-accordion';
import './organisms/carousel/carousel';
import './organisms/form-builder/form-builder';
import './organisms/news-filters/news-filters';
import './organisms/services-filters/services-filters';
import './organisms/projects-filters/projects-filters';
import './organisms/tab-anchor/tab-anchor';
import './organisms/footer/footer';
import './organisms/slides/simple-slider/simple-slider';

// Templates
import './templates/widgets-composer/slider-card-picture/slider-card-picture';
import './templates/widgets-composer/inclined-photo/inclined-photo';

